import * as React from 'react'
import { useMessage } from './fetch'

interface Client {
  id: string
  isNew: boolean
  createdAt: string
}

interface ClientIdentityOptions {
  loading: boolean
  error?: Error
  client?: Client
  reload?: () => void
}

const ClientIdentity = React.createContext<ClientIdentityOptions>({
  loading: typeof window !== 'undefined',
})

export const ClientIdentityProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [updated, update] = React.useState(true)
  const reload = React.useCallback(() => update(v => !v), [update])
  const [data, setOptions] = React.useState<ClientIdentityOptions>({
    loading: typeof window !== 'undefined',
    reload,
  })

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    ;(async () => {
      let user: Client | undefined

      try {
        user = await getUser()
      } catch (e) {
        setOptions({ error: e as Error, loading: false, reload })
        return
      }

      if (user) {
        setOptions({ client: user, loading: false, reload })
        return
      }

      const newId = Math.floor(Math.random() * 10000) + ''
      const client = {
        id: newId,
        isNew: true,
        createdAt: new Date().toString(),
      }
      setOptions({ client, loading: false, reload })
      await setUser(client)
    })()
  }, [reload, updated])

  return (
    <ClientIdentity.Provider value={data}>{children}</ClientIdentity.Provider>
  )
}

export const useClientIdentity = () => {
  return React.useContext(ClientIdentity)
}

type UseUserActionOptions = [
  (event: string) => void,
  { loading: boolean; error?: Error; client?: Client },
]

export const useUserAction = (): UseUserActionOptions => {
  const { loading, error, client, reload } = useClientIdentity()
  const [send] = useMessage()

  const track = React.useCallback(
    async (action: string) => {
      const message = `Un usuario ha presionado el botón "${action}" - ${
        client?.isNew === true
          ? ''
          : `Fecha de creación del usuario: ${
              client?.createdAt || 'Indefinido'
            }`
      }`

      await send({
        name: 'Cliente potencial',
        phone: client?.isNew
          ? 'Nuevo en la página'
          : 'Ya ha estado en la página antes',
        message,
      })

      reload?.()
    },
    [client, send, reload],
  )

  return [track, { loading, client, error }]
}

const key = 'dom:user'
const delay = 0

async function setUser(user: Client): Promise<void> {
  try {
    await setItem(key, JSON.stringify(user))
  } catch (e) {}
}

async function getUser(): Promise<Client | undefined> {
  let data: string | null = null

  try {
    data = await getItem(key)
  } catch (e) {}

  if (!data) {
    return
  }

  try {
    const parsed = JSON.parse(data)

    if (
      !parsed ||
      typeof parsed !== 'object' ||
      !parsed.id ||
      !parsed.createdAt
    ) {
      removeItem(key)
      return
    }

    return { ...parsed, isNew: false }
  } catch (e) {
    removeItem(key)
    return
  }
}

function setItem(key: string, value: string): Promise<void> {
  return new Promise(r => {
    setTimeout(() => r(window.localStorage.setItem(key, value)), delay)
  })
}

function getItem(key: string): Promise<string | null> {
  return new Promise(r => {
    setTimeout(() => r(window.localStorage.getItem(key)), delay)
  })
}

function removeItem(key: string): Promise<void> {
  return new Promise(r => {
    setTimeout(() => r(window.localStorage.removeItem(key)), delay)
  })
}
