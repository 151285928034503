import * as React from 'react'
import { ClientIdentityProvider } from './client-identity'

export interface AppProps {
  children: React.ReactNode
}

export const App: React.FC<AppProps> = props => {
  const mounted = React.useRef(false)

  React.useEffect(() => {
    if (mounted.current) {
      return
    }

    const container = document.createElement('div')
    container.id = 'toast-container'

    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return <ClientIdentityProvider>{props.children}</ClientIdentityProvider>
}
