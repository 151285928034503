const config = {
  main: {
    name: 'Domicilios 24/7',
    image: () => config.main.url + '/logo_small_icon_only_inverted.png',
    address: {
      addressCountry: 'CO',
      addressLocality: 'Bogotá',
      streetAddress: 'Calle 42 # 29 - 24 Sur',
    },
    schedules: {
      text: 'Lunes a Domingo, todo el día',
      ld: [
        {
          dayOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          opens: '00:00',
          closes: '23:59',
        },
      ],
    },
    priceRange: {
      text: "$10.000 - $12'000.000",
      ld: '10 $ - 12000 $',
    },

    url: 'https://www.domicilios247.com',
    currenciesAccepted: ['COP'],
    paymentAccepted: [
      'Efectivo',
      'Nequi',
      'Daviplata',
      'Transferencias Bancarias',
    ],
  },

  contact: {
    phoneNumbers: ['3138731616', '7979724'],
    extendedPhoneNumbers: ['+573138731616', '(1) 7979724'],
    email: 'info@domicilios247.com',
  },

  social: {
    instagram:
      'https://instagram.com/ferreteriadomicilios24?igshid=1tmbqqrl7g7a',
    facebook:
      'https://www.facebook.com/Ferreter%C3%ADa-Domicilios-247-106391034742932/',
    whatsappMessage: () => {
      let moment = 'Buenos dias'
      const hour = new Date().getHours()

      if (hour >= 18) {
        moment = 'Buenas noches'
      } else if (hour >= 12) {
        moment = 'Buenas tardes'
      }

      return `${moment}. Necesito un servicio de `
    },
  },
}

export default config
