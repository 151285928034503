import { useState, useCallback } from 'react'
import config from '../components/config'

export const useFetch = <Input extends {}, Output extends object>(props: {
  url: string
  method: string
}): [
  (input: Input) => Promise<Output>,
  { loading: boolean; error?: Error; data?: Output },
] => {
  const [data, setData] = useState<Output>()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  const send = useCallback(
    async (input: any): Promise<any> => {
      setLoading(true)

      try {
        const pathname =
          window.location.pathname === '/'
            ? 'Ferretería'
            : window.location.pathname

        const response = await fetch(props.url, {
          method: props.method,
          cache: 'no-cache',
          redirect: 'follow',
          body: JSON.stringify({
            ...input,
            message:
              `${input?.message || ''} ` +
              `Página: "${pathname}" ` +
              `Refiere: "${document.referrer}"`,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        const body = await response.json()
        setData(body)
        return body
      } catch (e) {
        setError(e as Error)
        throw e
      } finally {
        setLoading(false)
      }
    },
    [props.method, props.url],
  )

  return [send, { loading, error, data }]
}

export const track = () => {
  // @ts-ignore if the 'ga' script is not loaded, then it'll be undefined.
  if (typeof window !== 'undefined' && window.ga) {
    ga('send', 'social', 'Facebook', 'Visit', config.social.facebook)
  }
}

export const useMessage = () => {
  const url = process.env.GATSBY_API_URL

  if (!url) {
    throw new Error('Environment variable "GATSBY_API_URL" can\'t be empty')
  }

  return useFetch<any, { message?: string }>({
    url,
    method: 'POST',
  })
}
